<template>
    <div class="tsms">
        <div class="left view" @click="$router.push(`/fooddetail?id=${p1.id}&type=1`)">
            <el-image fit="cover" style="width:100%;height:100%"  :src="p1.photoUrls[0]"></el-image>
            <div class="title" v-show="p1.id !== actItem.id">{{p1.name}}</div>
            
        </div>
        <div class="right">
            <div class="r-t view" @click="$router.push(`/fooddetail?id=${p2.id}&type=1`)">
                <el-image fit="cover" style="width:100%;height:100%"  :src="p2.photoUrls[0]"></el-image>
                <div class="title" v-show="p2.id !== actItem.id">{{p2.name}}</div>
            </div>
            <div class="r-b">
                <div class="rb-t view" @click="$router.push(`/fooddetail?id=${p3.id}&type=1`)">
                    <el-image fit="cover" style="width:100%;height:100%"  :src="p3.photoUrls[0]"></el-image>
                    <div class="title" v-show="p3.id !== actItem.id">{{p3.name}}</div>
                </div>
                <div class="rb-b view" @click="$router.push(`/fooddetail?id=${p4.id}&type=1`)">
                    <el-image fit="cover" style="width:100%;height:100%"  :src="p4.photoUrls[0]"></el-image>
                    <div class="title" v-show="p4.id !== actItem.id">{{p4.name}}</div>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="name">{{actItem.name}}</div>
            <div class="text">{{actItem.introduction}}</div>
        </div>
    </div>
</template>

<script>
import {  GetFoodPage, } from '@/api/home'

export default {
    data(){
        return{
            foods:[{},{},{},{}],
            p1:{photoUrls:[],name:''},
            p2:{photoUrls:[],name:''},
            p3:{photoUrls:[],name:''},
            p4:{photoUrls:[],name:''},
            actItem:{},
        }
    },
    methods:{
        //获取特色美食
        getFoods(){
            const data = { currentPage:1, pageSize:4,orderName:'Sort',orderDesc:false};
            GetFoodPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach((d,k) => {
                        d.photoUrls = d.photoUrls.split(',');
                        this[`p${k+1}`] = d;
                    })
                    this.foods = data;
                    this.actItem = this.foods[0];
                }
            })
        },
    },
    mounted(){
        this.getFoods();
        let oDiv = document.getElementsByClassName('tsms')[0];
        let info = document.getElementsByClassName('info')[0];
        let that = this
        oDiv.addEventListener('mouseenter',function(e){
            if(e.target.className.indexOf('left')>-1 ){
                info.style.width = '500px'
                info.style.left = '55px'
                info.style.top = '320px'
                that.actItem = that.p1
            }else if(e.target.className.indexOf('r-t')>-1 ){
                info.style.width = '500px'
                info.style.left = '710px'
                info.style.top = '100px'
                that.actItem = that.p2
            }else if(e.target.className.indexOf('rb-t')>-1 ){
                info.style.width = '305px'
                info.style.left = '640px'
                info.style.top = '400px'
                that.actItem = that.p3
            }else if(e.target.className.indexOf('rb-b')>-1 ){
                info.style.width = '305px'
                info.style.left = '975px'
                info.style.top = '400px'
                that.actItem = that.p4
            }

        },true);

    }
}
</script>

<style scoped>
.imgbox{
    height: 100%;
}
.title{
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to top,rgba(0, 0, 0,.9) 0%,rgba(0, 0, 0,.7) 40%, transparent);
    width: 100%;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
}
.left{
    width: 610px;
    position: relative;
}
.right{
    width: calc(100% - 640px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100%; */
}
.r-t{
    height: 260px;
    background-color: #888;
    position: relative;
}
.r-b{
    height: 320px;
    display: flex;
    justify-content: space-between;
}
.rb-t,.rb-b{
    width: 305px;
    background-color: #eee;
    position: relative;
}
.tsms{
    display: flex;
    justify-content: space-between;
    height: 610px;
    overflow: hidden;
    position: relative;
}
.info{
    position: absolute;
    top: 320px;
    left: 55px;
    width: 500px;
    height: 100px;
    background: rgba(255, 255, 255, .7);
    display: flex;
    padding: 20px 10px;
    justify-content: space-between;
    transition: all .8s ;
    overflow: hidden;
}
.info>.name{
    width: 23%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    font-size:18px;
    color: #367bff;
    font-weight: bold;
}
.info>.text{
    width: 75%;
    height: 100%;
    font-size: 14px;
    line-height: 20px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
}
</style>