<template>
    <div class="tsphb">
        <div class="list">
            <h2 class="p-h2">
                <img src="../../assets/img/xnh/title-shop4.png" alt="">
            </h2>
            <div class="main">
                <div class="item" v-for="(item,k) in productsPhb" :key="item.id" @click="item.id&&$router.push(`/productdetail?id=${item.id}&type=1`)">
                    <div class="imgbox">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="item.photoUrls[0]"></el-image>
                        <div class="Nobox" v-if="k<3">
                            {{k+1}}
                        </div>
                    </div>
                    <div class="infos">
                        <div class="name">{{item.name}}</div>
                        <div class="rote">
                            <el-rate
                                v-model="item.evaluateStar"
                                disabled
                                show-score
                                text-color="#ff9900"
                                score-template="{value}分">
                            </el-rate>
                        </div>
                        <div class="info">
                            {{item.introduction}}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="list">
            <h2 class="p-h2">
                <img src="../../assets/img/xnh/title-shop5.png" alt="">
            </h2>
            <div class="main">
                <div class="item" v-for="(item,k) in foodsPhb" :key="item.id" @click="item.id&&$router.push(`/fooddetail?id=${item.id}&type=1`)">
                    <div class="imgbox">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="item.photoUrls[0]"></el-image>
                        <div class="Nobox" v-if="k<3">
                            {{k+1}}
                        </div>
                    </div>
                    <div class="infos">
                        <div class="name">{{item.name}}</div>
                        <div class="rote">
                            <el-rate
                                v-model="item.evaluateStar"
                                disabled
                                show-score
                                text-color="#ff9900"
                                score-template="{value}分">
                            </el-rate>
                        </div>
                        <div class="info">
                            {{item.introduction}}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- <div class="list">
            <h2 class="p-h2">
                <img src="../../assets/img/xnh/title-shop6.png" alt="">
            </h2>
            <div class="main">
                <div class="item" v-for="(item,k) in shardeFarmDP" :key="item.id" @click="item.id&&$router.push(`/shopdetail?id=${item.id}&type=1`)">
                    <div class="imgbox">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="item.photoUrls[0]"></el-image>
                        <div class="Nobox" v-if="k<3">
                            {{k+1}}
                        </div>
                    </div>
                    <div class="infos">
                        <div class="name ellipsis">{{item.name}}</div>
                        <div class="rote">
                            <el-rate
                                v-model="item.evaluateStar"
                                disabled
                                show-score
                                text-color="#ff9900"
                                score-template="{value}分">
                            </el-rate>
                        </div>
                        <div class="info">
                            {{item.introduction}}
                        </div>
                    </div>
                </div>
            </div>
            
        </div> -->
    </div>
</template>

<script>
import { GetProductPageV2, GetFoodPageV2,GetSharedFarmPageV2 } from '@/api/home'
export default {
    data(){
        return{
            productsPhb:[],
            foodsPhb:[],
            shardeFarmDP:[],
            sharedFarms:[
                {photoUrls:[require('../../../public/img/xnh/xng/p1.png')],evaluateStar:4.7, name:"湟中暖锅楼(演示数据)",introduction:"(演示数据)印象云谷川景区,国家3A级景区，体验集特色餐饮、民俗民居、休闲度假、生态观光等多功能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区..."},
                {photoUrls:[require('../../../public/img/xnh/xng/p2.png')],evaluateStar:4.7, name:"云川辣子坊(演示数据)",introduction:"(演示数据)印象云谷川景区,国家3A级景区，体验集特色餐饮、民俗民居、休闲度假、生态观光等多功能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区..."},
                {photoUrls:[require('../../../public/img/xnh/xng/p3.png')],evaluateStar:4.7, name:"格桑花家具体验馆(演示数据)",introduction:"(演示数据)印象云谷川景区,国家3A级景区，体验集特色餐饮、民俗民居、休闲度假、生态观光等多功能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区..."},
                {photoUrls:[require('../../../public/img/xnh/xng/p3.png')],evaluateStar:4.7, name:"格桑花家具体验馆(演示数据)",introduction:"(演示数据)印象云谷川景区,国家3A级景区，体验集特色餐饮、民俗民居、休闲度假、生态观光等多功能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区..."},
                {photoUrls:[require('../../../public/img/xnh/xng/p3.png')],evaluateStar:4.7, name:"格桑花家具体验馆(演示数据)",introduction:"(演示数据)印象云谷川景区,国家3A级景区，体验集特色餐饮、民俗民居、休闲度假、生态观光等多功能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区..."},
            ],
        }
    },
    methods:{
        //获取特色产品排行榜
        getProductsPhb(){
            const data = { currentPage:1, pageSize:5, orderName:'EvaluateStar', orderDesc:true };
            GetProductPageV2(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                        //d.evaluateStar = d.evaluateStar || 4.2;
                    })
                    this.productsPhb = data;
                }
            })
        },
        //获取特色美食排行榜
        getFoodsPhb(){
            const data = { currentPage:1, pageSize:5, orderName:'EvaluateStar', orderDesc:true };
            GetFoodPageV2(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                        //d.evaluateStar = d.evaluateStar || 4.3;
                    })
                    this.foodsPhb = data;
                }
            })
        },
        //店铺排行榜
        getSharedFarmDP(){
            const data = { filter:{businessType:'特色店铺'}, currentPage:1, pageSize:5, orderName:'EvaluateStar', orderDesc:true };
            GetSharedFarmPageV2(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                        //d.evaluateStar = d.evaluateStar || 4.7;
                    })
                    
                    this.shardeFarmDP = data;
                    if(this.shardeFarmDP.length==0){
                        this.shardeFarmDP = this.sharedFarms
                    }
                }
            })
        },
    },
    mounted(){
        this.getProductsPhb();
        this.getFoodsPhb();
        this.getSharedFarmDP();
    }
}
</script>

<style scoped>
.infos{
    width: calc(100% - 152px);
}
.name{
    line-height: 32px;
    font-size: 18px;
    font-weight: bold;
}
.rote{
    padding: 6px 0;
}
.info{
    line-height: 18px;
    font-size: 14px;
    color:#333;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
}
.item{
    display: flex;
    justify-content: space-between;
    height: 120px;
    overflow: hidden;
    cursor: pointer;
}
.imgbox{
    width: 140px;
    height: 120px;
}
.p-h2{
    padding: 20px;
    text-align: center;
}
.tsphb{
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}
.main{
    width:600px;
    height: 720px;
    overflow: hidden;
    background: #f1f5f9;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>