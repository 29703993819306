<!--乡宁购-->
<template>
    <div class="shopping ">
        <!-- 特色产品 -->
        <div>
            <table></table>
            <div class="floor-title w-1400 ">
                <img src="../../assets/img/xnh/title-shop1.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <tscp></tscp>   
                <div class="more" @click="$router.push('/productlist')">
                    <span>查看更多</span>
                    <img src="../../assets/img/hot-icon/more.png" alt="">
                </div>            
            </div>
        </div>
        <!-- 特色美食 -->
        <div>
            <table></table>
            <div class="floor-title w-1400 ">
                <img src="../../assets/img/xnh/title-shop2.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <tsms></tsms>   
                <div class="more" @click="$router.push('/foodlist')">
                    <span>查看更多</span>
                    <img src="../../assets/img/hot-icon/more.png" alt="">
                </div>            
            </div>
        </div>
        <!-- 店铺推荐 -->
        <!-- <div>
            <table></table>
            <div class="floor-title w-1400 ">
                <img src="../../assets/img/xnh/title-shop3.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <tsdp></tsdp>   
                <div class="more" @click="$router.push('/shopList')">
                    <span>查看更多</span>
                    <img src="../../assets/img/hot-icon/more.png" alt="">
                </div>            
            </div>
        </div> -->
        <!-- 排行榜 -->
        <div>
            <table></table>
            <div class="mt-20 w-1280 pb-20">
                <tsphb></tsphb>         
            </div>
        </div>
        <!-- <el-row :gutter="30">
            <el-col :span="17">
                <div class="feature">
                    <div class="shopping-title">
                        <div class="fl h5">特色产品</div>
                        <div class="fr more"> <router-link to="/productlist">更多<i class="el-icon-arrow-right"></i></router-link> </div>
                    </div>
                    <div class="feature-main mt-20">
                        <el-row :gutter="10">
                            <el-col :span="14">
                                <div v-if="products1[productact]" class="feature-picbig">
                                    <el-carousel trigger="click" height="450px"  arrow="never">
                                        <el-carousel-item v-for="item in products1[productact].photoUrls" :key="item">
                                        <router-link :to="`/productdetail?id=${ products1[productact].id}&type=1`">                                            
                                            <el-image style="width:100%;height:100%" :src="item"></el-image>
                                        </router-link>
                                        </el-carousel-item>
                                    </el-carousel>
                                </div>
                            </el-col>
                            <el-col :span="10">
                                <el-row :gutter="10">
                                    <el-col :span="12" v-for="(item,index) in products1" :key="item.id" :class="{'mt-10':item>2}">
                                        <div class="feature-small" @click="onProductChange(index)">
                                            <el-image style="width:100%;height:100%" :src="item.photoUrls[0]"></el-image>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="feature-carousel mt-20">
                        <div class="carousel-box">
                            <ul class="carousel-inner clearfix" :style="`width:${carData.length*285}px;transform:translateX(${translateNum}px)`">
                                <li class="carousel-item fl" v-for="(item,index) in products2" :key="index+'ii'">
                                    <div class="fl carousel-item-pic">
                                        <router-link :to="`/productdetail?id=${item.id}&type=1`">
                                        <el-image style="width:100%;height:100%" :src="item.photoUrls[0]"></el-image>
                                        </router-link>
                                    </div>
                                    <div class="fr carousel-item-info">
                                        <p>{{item.name}}</p>
                                        <p>{{item.unit}}({{item.specifications}})</p>
                                        <p>促销价：<span class="price">¥{{item.price}}</span></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="btn-prev" @click="prev" :class="{disabled:translateNum==0}"><i class="el-icon-arrow-left"></i></div>
                        <div class="btn-next" @click="next" :class="{disabled:translateNum==carData.length*(-285)+855}"><i class="el-icon-arrow-right"></i></div>
                    </div>
                </div>
                <div class="food mt-20">
                    <div class="shopping-title">
                        <div class="fl h5">特色美食</div>
                        <div class="fr more"><router-link to="/foodlist"> 更多<i class="el-icon-arrow-right"></i></router-link></div>
                    </div>
                    <div class="food-main">
                        <el-row :gutter="15">
                            <el-col :span="8" v-for="item in foods" :key="item.id">
                                <router-link :to="`/fooddetail?id=${item.id}&type=1`">
                                    <div class="food-item">
                                        <div class="sj-ceng"></div>
                                        <div class="food-title ellipsis">{{item.name}}</div>
                                        <div class="text-ceng">
                                            <div>
                                                <p class="t1">{{item.name}}</p>
                                                <p class="t2">{{item.introduction}}</p>
                                            </div>
                                        </div>
                                        <el-image style="width:100%;height:100%" :src="item.photoUrls[0]"></el-image>
                                    </div>   
                                </router-link>
                            </el-col>
                        </el-row>
                        <div class="inner-text"></div>
                    </div>
                </div>
                <div class="shop mt-20">
                    <div class="shopping-title">
                        <div class="fl h5">店铺推荐</div>
                        <router-link to="/shopList">
                        <div class="fr more">更多<i class="el-icon-arrow-right"></i></div>
                        </router-link>
                    </div>
                    <div class="shop-main">
                        <el-row :gutter="15">
                            <el-col :span="12" v-for="d in sharedFarms" :key="d.id">
                                <router-link :to="`/shopdetail?id=${d.id}`">
                                    <div class="shop-item">
                                        <div class="shop-item-innerbox clearfix">
                                            <div class="shop-pic fl">
                                                <el-image style="width:100%;height:100%" :src="d.photoUrls[0]"></el-image>
                                            </div>
                                            <div class="shop-info fr">
                                                <div class="shop-text">
                                                    <p>{{d.name}}</p>
                                                    <p>{{d.introduction}}</p>
                                                </div>
                                                
                                            </div>
                                        </div>  
                                    </div>
                                </router-link>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-col>
            <el-col :span="7">
                <div class="foods-classify ">
                    <div class="foods-top10-h3">
                        <i class="iconfont icon-fuzhushuxian"></i>
                        <span>特色产品排行榜</span>
                    </div>
                    <div class="card2 clearfix" v-for="(item,index) in productsPhb" :key="item.id">
                  <router-link :to="`/productdetail?id=${item.id}&type=1`">
                        <div class="fl card2-pic">
                            <div class="fl foods-others-name pro">
                                <p class="fl" :class="[{'bg-1':index==0},{'bg-2':index==1},{'bg-3':index==2},{'bg-4':index==3}]">{{index+1}}</p>
                            <el-image :src="item.photoUrls[0]" style="width:100%;height:100%" fit="cover"></el-image>
                            </div>
                        </div>
                        <div class="fl card2-info">
                            <div class="card2-name">{{item.name}}</div>
                            <div class="card2-intro">{{item.introduction}}</div>
                        </div>
                   </router-link>
                    </div>
                </div>
                <div class="foods-top10 clearfix mt-20">
                    <div class="foods-top10-h3">
                        <i class="iconfont icon-fuzhushuxian"></i>
                        <span>特色美食排行榜</span>
                    </div>
                    <div class="foods-top1 clearfix" v-for="(item,index) in foodsPhb" :key="item.id">
                        <router-link :to="`/fooddetail?id=${item.id}&type=1`">
                                <div class="foods-top1-pic fl foods-others-name pro">
                                    <p class="fl" :class="[{'bg-1':index==0},{'bg-2':index==1},{'bg-3':index==2},{'bg-4':index==3}]">{{index+1}}</p>
                                <el-image :src="item.photoUrls[0]" style="width:100%;height:100%" fit="cover"></el-image>
                                </div>
                            <div class="foods-top1-info fr">
                                <div class="clearfix foods-top1-name">
                                    <div class="fl ellipsis">{{item.name}}</div>
                                </div>
                                <div><span style="color:#f9773b;margin-right:10px">菜系</span>{{item.cuisine}}</div>
                                <div><span style="color:#1c91ff;margin-right:10px">价格</span><span style="color:red">￥</span>{{item.price}}元/份</div>
                            </div> 
                        </router-link>
                    </div>
                </div>
                <div class="foods-classify ">
                    <div class="foods-top10-h3">
                        <i class="iconfont icon-fuzhushuxian"></i>
                        <span>特色店铺排行榜</span>
                    </div>
                    <div class="card2 clearfix" v-for="(v,index) in shardeFarmDP" :key="v.id">
                  <router-link :to="`/shopdetail?id=${v.id}&type=1`">
                        <div class="fl card2-pic">
                            <div class="fl foods-others-name pro">
                                <p class="fl" :class="[{'bg-1':index==0},{'bg-2':index==1},{'bg-3':index==2},{'bg-4':index==3}]">{{index+1}}</p>
                            <el-image :src="v.photoUrls[0]" style="width:100%;height:100%" fit="cover"></el-image>
                            </div>
                        </div>
                        <div class="fl card2-info">
                            <div class="card2-name ellipsis">{{v.name}}</div>
                            <div class="card2-intro">{{v.introduction}}</div>
                        </div>
                   </router-link>
                    </div>
                </div>
            </el-col>
        </el-row> -->
    </div>
</template>
<script>
import { GetProductPage, GetFoodPage,GetSharedFarmPage } from '@/api/home'
import tscp from '@/components/xiangninghui/tscp'//特色产品
import tsms from '@/components/xiangninghui/tsms'//特色美食
import tsdp from '@/components/xiangninghui/tsdp'//特色店铺
import tsphb from '@/components/xiangninghui/tsphb'//特色排行榜
export default {
    components:{
        tscp,tsms,tsdp,tsphb
    },
    data(){
        return{
            products1: [], //特色产品
            products2: [], //特色产品
            productact: 0,
            productsPhb: [], //特色产品排行榜
            carData:[{},{},{},{},{}],
            translateNum:0,
            foods: [], //特色美食
            foodsPhb: [], //特色美食排行榜
            foodsPhb1: null,
            sharedFarms:[],//店铺
            shardeFarmDP:[], //店铺排行榜
        }
    },
    computed:{
        
    },
    mounted(){
        // this.getProducts();
        // this.getProductsPhb();
        // this.getFoods();
        // this.getFoodsPhb();
        // this.getSharedFarms();
        // this.getSharedFarmDP();
    },
    methods:{
        //获取特色产品
        getProducts(){
            const data = { currentPage:1, pageSize:9 };
            GetProductPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.products1 = data;
                    if (this.products1.length > 4) {
                        this.products2 = this.products1.splice(4, this.products1.length-4);
                    } else {
                        this.products2 = [];
                    }
                }
            })
        },
        onProductChange(index){
            this.productact = index;
        },
        //获取特色产品排行榜
        getProductsPhb(){
            const data = { currentPage:1, pageSize:5 };
            GetProductPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.productsPhb = data;
                }
            })
        },
        prev(){
            if(this.translateNum==0) return false;
            this.translateNum += 285;
        },
        next(){
            if(this.translateNum==this.carData.length*(-285)+855) return false;
            this.translateNum -= 285;
        },
        //获取特色美食
        getFoods(){
            const data = { currentPage:1, pageSize:9 };
            GetFoodPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.foods = data;
                }
            })
        },
        //获取特色美食排行榜
        getFoodsPhb(){
            const data = { currentPage:1, pageSize:6 };
            GetFoodPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.foodsPhb = data;
                    if (this.foodsPhb.length > 1) {
                        var arr = this.foodsPhb.splice(0, 1);
                        this.foodsPhb1 = arr[0];
                    } else {
                        this.foodsPhb1 = null;
                    }
                }
            })
        },
        //店铺
        getSharedFarms() {
            const data = { filter:{businessType:'特色店铺'}, currentPage:1, pageSize:6};
            GetSharedFarmPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.sharedFarms = data;
                    // if (this.sharedFarms.length > 6) {
                    //     this.shardeFarmDP = this.sharedFarms.splice(6, this.sharedFarms.length-6);
                    // } else {
                    //     this.shardeFarmDP = [];
                    // }
                }
            })
        },
        //店铺排行榜
        getSharedFarmDP(){
            const data = { filter:{businessType:'特色店铺'}, currentPage:1, pageSize:5};
            GetSharedFarmPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.shardeFarmDP = data;
                }
            })
        },
    }
}
</script>
<style scoped>
.more{
    width: 240px;
    line-height: 42px;
    font-size: 16px;
    color: #898989;
    border: 2px solid #ccc;
    margin: 80px auto 40px auto;
    text-align: center;
    cursor: pointer;
    transition: all .8s;
}
.more:hover{
    background: rgb(211, 223, 240);
    /* border-color: #eee; */
    /* color: #fff; */
}
.more>span{
    margin-right: 20px;
}
.floor-title{
    color:#000;
    text-align: center;
    font-size: 40px;
    padding: 30px 0;
}
.foods-classify-item .No{
    padding: 0 12px;
    background: #f9773b;
    font-size: 16px;
}
.card2-intro{
    text-indent: 20px;
    font-size: 16px;
    line-height: 20px;
    min-height: 60px;
    font-size: '微软雅黑';
    text-overflow: -o-elllipsis-lastline;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:4;
    -webkit-box-orient:vertical;
    
}
.pro{
    width: 130px;
    height: 110px;
}
.card-name{
    font-size: 20px;
    line-height: 36px;
    font-weight: 700;
}
.card2-name{
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
}
.card2-info{
    height: 110px;
    overflow: hidden;
}
.card-info{
    width: calc(100% - 255px);
    margin-left: 18px;
}
.card-info span{
    color:#999
}
.card2-info{
    width: calc(100% - 150px);
    margin-left: 18px;
}
.card-pic{
    width: 235px;
    height: 175px;
    overflow: hidden;
}
.card2-pic{
    width: 130px;
    height: 110px;
    overflow: hidden;
}
.card2{
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
}
.card2~.card2{
    border-top: 0;
}
.food-item:hover .text-ceng{
     /* width: 100%; */
    opacity: 1; 
    left: 0;
}
.text-ceng>div .t1{
    text-align: center;
    line-height: 36px;

}
.text-ceng>div{
    display: table-cell;
    vertical-align: middle;
    padding: 20px;
    font-size: 15px;
    line-height: 24px;
    width: 100%;
    height: 100%;
}
.text-ceng{
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 4;
    /* padding:10px; */
    background: rgba(0, 0, 0, .5);
    color: #fff;
    /* height: 0; */
    opacity: 0;
    overflow: hidden;
    display: table;
    transition: all 1.2s;
}
.food-main{
    position: relative;
}
.sj-ceng{
    /* border-top: 50px solid #ff4761; */
    border-right: 270.4px solid transparent;
    border-bottom: 60px solid #ff4761;
    /* border-right: 100% solid transparent; */
    position: absolute;
    left: 0;
    bottom: 0;
    /* box-sizing: content-box; */
    z-index: 2;
}
.foods-classify-item.actt,.foods-classify-item:hover{
    color: #fff;
    background: #0169ca;
}
.foods-classify-item{
    height: 46px;
    line-height: 46px;
    border-radius: 10px;
    font-size: 22px;
    background: #eee;
    /* text-align: center; */
    margin-top: 15px;
    cursor: pointer;
}
/* 七种颜色排行 红橙黄绿青蓝紫*/
.foods-others-name>p:first-child.bg-1{
    background: #ff4761;
    color: #0169ca;
}
.foods-others-name>p:first-child.bg-2{
    background: #f9773b;
    color: #0169ca;
}
.foods-others-name>p:first-child.bg-3{
    background: yellow;
    color: #0169ca;
}
.foods-others-name>p:first-child.bg-4{
    background: greenyellow;
    color: #0169ca;
}
.foods-others-name.pro>p{
    position: absolute;
    left: 0;
    bottom: 2px;
    z-index: 1;
}

.foods-others-name{
    position: relative;
    height: 100%;
}
.foods-item{
    padding: 12px 0;
    border-bottom: 1px solid #ccc;
}
.foods-item:last-child{
    border:0;
}
.foods-others-sortnum>p:first-child.c-2{
    color: #ff4761;
}
.foods-others-sortnum>p:first-child{
    color: #1c91ff;
}
.foods-others-sortnum>p{
    font-size: 14px;
    line-height: 14px;
    text-align: center;
}
.foods-others-name>p:first-child{
    font-size: 20px;
    width: 25px;
    height: 25px;
    line-height: 20px;
    background: #ddd;
    text-align: center;
    margin-right: 5px;
    box-sizing: border-box;
    transform: translateY(4px);
}
.foods-others-inner{
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    font-weight: 600;
    padding:0 4px;
    color: #666;
}
.foods-top1-name .lift p{
    font-size: 16px;
    height: 22px;
    line-height: 22px;
    font-weight: 500;
    text-align: center;
}
.foods-top1-name{
    font-size: 20px;
    height: 44px;
    line-height: 44px;
    font-weight: 700;
}
.foods-top10-h3 .iconfont{
    font-size: 22px;
}
.foods-top10{
    border: 2px solid #ccc;
}
.foods-top10-h3{
    height: 42px;
    line-height: 42px;
    font-size: 22px;
    color: #0169ca;
    font-weight: 600;
}
.foods-top1-pic .sortnum{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    text-align:center ;
    line-height: 20px;
    background: #f9773b;
    color: #fff;
}
.foods-top1-info{
    width: calc(100% - 158px);
    font-size: 15px;
    line-height: 1.5;
    font-family: '微软雅黑';
}
.foods-top1-pic{
    width: 130px;
    height: 110px;
    overflow: hidden;
    position: relative;
}
.foods-top1{
    padding: 12px 4px;
    border-bottom: 1px solid #ccc;
    /* height: 110px; */
}
.shop-text>p:last-child{
    font-size: 16px;
    padding: 5px 0;
    font-weight: 600;
    text-indent: 18px;
    line-height: 28px;
}
.shop-text>p:first-child{
    font-size: 20px;
    padding: 5px 0;
    font-weight: 700;
}
.shop-text{
    text-indent: 20px;
    font-size: 16px;
    line-height: 20px;
    min-height: 60px;
    font-size: '微软雅黑';
    text-overflow: -o-elllipsis-lastline;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:6;
    -webkit-box-orient:vertical;
}
.shop-info{
    width: calc(100% - 225px);
    height: 180px;
    display: table;
}
.shop-pic{
    width: 210px;
    height: 180px;
    overflow: hidden;
}
.shop-item-innerbox{
    padding: 10px;
}
.shop-item:hover .shop-item-innerbox{
    border: 1px solid #ccc;
    color:#fff
}
.shop-item:hover{
    border: 0;
    background: #0169ca;
}
.shop-item{
    height: 220px;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 20px;
    transition: background 0.8s;
}
.food-item .food-title{
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    text-align: center;
    color: #fff;
    width: 50%;
    /* background: rgba(0, 0, 0, 0.7); */
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
}
.food-item{
    width: 100%;
    height: 240px;
    overflow: hidden;
    position: relative;
    margin-top: 15px;
}
.btn-prev{
    left: 0;
}
.btn-next{
    right: 0;
}
.btn-prev.disabled,.btn-prev.disabled:hover,.btn-next.disabled,.btn-next.disabled:hover{
    color: #ccc;
}
.btn-prev:hover,.btn-next:hover{
    color: #1c91ff;
    background: #f1f1f1;
}
.btn-prev,.btn-next{
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    color: #0169ca;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
    cursor: pointer;
}
.carousel-item-info .price{
    color:#f9773b;
}
.carousel-item-info p{
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-family: '黑体';
}
.carousel-inner{
    width: 1800px;
    transition: transform 1.5s;
}
.carousel-item-info{
    width: 145px;
    height: 90px;
}
.carousel-item-pic{
    width: 105px;
    height: 90px;
}
.carousel-item{
    width: 270px;
    height: 150px;
    margin-right: 15px;
    background: #eee;
    padding: 30px 5px;
    box-sizing: border-box;
}
.carousel-box{
    height: 150px;
    width: 840px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.feature-carousel{
    height: 150px;
    width: 100%;
    position: relative;
}
.feature-small{
    width: 100%;
    height: 220px;
}
.mt-10{
    margin-top: 10px;
}
.feature-picbig >>> .el-carousel__button{
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.mt-20{
    margin-top: 20px;
}
.feature-picbig{
    width: 100%;
    height: 450px;
}
.shopping-title .h5{
    color:#0169ca;
}
.shopping-title{
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
}
/* .shopping-title .more:hover{
    color: #1c91ff;
    cursor: pointer;
} */
</style>