<template>
    <div class="tsdp">
        <div class="dp-item" v-for="item in sharedFarms" :key="item.id">
            <div class="imgbox" @click="item.id&&$router.push(`/shopdetail?id=${item.id}`)">
                <el-image fit="cover" style="width:100%;height:100%"  :src="item.photoUrls[0]"></el-image>
            </div>
            <div class="infos">
                <div class="title">{{item.name}}</div>
                <div class="info">{{item.introduction}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetSharedFarmPage } from '@/api/home'
export default {
    data(){
        return{
            sharedFarms:[
                {photoUrls:[require('../../../public/img/xnh/xng/p1.png')],name:"湟中暖锅楼(演示数据)",introduction:"(演示数据)印象云谷川景区,国家3A级景区，体验集特色餐饮、民俗民居、休闲度假、生态观光等多功能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区..."},
                {photoUrls:[require('../../../public/img/xnh/xng/p2.png')],name:"云川辣子坊(演示数据)",introduction:"(演示数据)印象云谷川景区,国家3A级景区，体验集特色餐饮、民俗民居、休闲度假、生态观光等多功能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区..."},
                {photoUrls:[require('../../../public/img/xnh/xng/p3.png')],name:"格桑花家具体验馆(演示数据)",introduction:"(演示数据)印象云谷川景区,国家3A级景区，体验集特色餐饮、民俗民居、休闲度假、生态观光等多功能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区...能为一体的乡村旅游度假景区..."},
            ],
        }
    },
    methods:{
        //店铺
        getSharedFarms() {
            const data = { filter:{businessType:'特色店铺'}, currentPage:1, pageSize:3};
            GetSharedFarmPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    if(data.length>0)
                    this.sharedFarms = data;
                }
            })
        },
    },
    mounted(){
        this.getSharedFarms();
    },
}
</script>

<style scoped>
.tsdp{
    display: flex;
    justify-content: space-between;
}
.dp-item{
    width: 400px;
    height: 500px;
    position: relative;
}
.imgbox{
    height: 300px;
    cursor: pointer;
}
.infos{
    width:360px;
    height: 225px;
    position: absolute;
    bottom: 10px;
    left: 20px;
    padding: 20px 24px;
    background: #f1f5f9;
}
.title{
    font-size: 20px;
    font-weight: bold;
    line-height: 42px;
}
.info{
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:4;
    -webkit-box-orient:vertical;
    font-size: 16px;
    line-height: 30px;
    text-indent: 20px;
}
</style>