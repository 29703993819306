<template>
    <div class="tscp">
        <div class="t-top">
            <div class="t-left">
                <div class="imgbox" @click="$router.push(`/productdetail?id=${ p1.id}&type=1`)">
                    <el-image fit="cover" style="width:100%;height:100%"  :src="p1.photoUrls[0]"></el-image>
                    <div class="title">{{p1.name}}</div>
                </div>
            </div>
            <div class="center">
                <el-carousel height="400px" arrow="never">
                    <el-carousel-item v-for="(item,k) in p2.photoUrls" :key="k+'house'" >
                        <div class="imgbox" @click="$router.push(`/productdetail?id=${ p2.id}&type=1`)">
                            <el-image fit="cover" style="width:100%;height:100%"  :src="item"></el-image>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="t-right">
                <div>
                    <div class="imgbox" @click="$router.push(`/productdetail?id=${ p3.id}&type=1`)">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="p3.photoUrls[0]"></el-image>
                        <div class="title">{{p3.name}}</div>
                    </div>
                </div>
                <div>
                    <div class="imgbox" @click="$router.push(`/productdetail?id=${ p4.id}&type=1`)">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="p4.photoUrls[0]"></el-image>
                        <div class="title">{{p4.name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="t-bottom">
            <div class="feature-carousel mt-20">
                <div class="carousel-box">
                    <ul class="carousel-inner clearfix" :style="`width:${carData.length*355}px;transform:translateX(${translateNum}px)`">
                        <li class="carousel-item fl" v-for="(item,index) in products2" :key="index+'ii'">
                            <div class="carousel-item-pic">
                                <router-link :to="`/productdetail?id=${item.id}&type=1`">
                                    <el-image style="width:100%;height:100%" :src="item.photoUrls[0]"></el-image>
                                </router-link>
                            </div>
                            <div class="carousel-item-info">
                                <p>{{item.name}}</p>
                                <p>规格：<span>{{item.unit}}({{item.specifications}})</span> 促销价：<span class="price">¥{{item.price}}</span></p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="btn-prev" @click="prev" :class="{disabled:translateNum==0}"><i class="el-icon-arrow-left"></i></div>
                <div class="btn-next" @click="next" :class="{disabled:translateNum==carData.length*(-355)+1065}"><i class="el-icon-arrow-right"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetProductPage, } from '@/api/home'
export default {
    data(){
        return{
            carData:[{},{},{},{},{}],
            p1:{photoUrls:[],name:'演示数据'},
            p2:{photoUrls:[],name:'演示数据'},
            p3:{photoUrls:[],name:'演示数据'},
            p4:{photoUrls:[],name:'演示数据'},
            products2:[],
            translateNum:0
        }
    },
    mounted(){
        this.getProducts();
    },
    methods:{
         //获取特色产品
        getProducts(){
            const data = { currentPage:1, pageSize:10,orderName:'Sort',orderDesc:false};
            GetProductPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach((d,k) => {
                        d.photoUrls = d.photoUrls.split(',');
                        if(k<4){
                            this[`p${k+1}`] = d;
                        }
                    })
                    this.products1 = data;
                    if (this.products1.length > 4) {
                        this.products2 = this.products1.splice(4, this.products1.length-4);
                    } else {
                        this.products2 = [];
                    }
                }
            })
        },
        prev(){
            if(this.translateNum==0) return false;
            this.translateNum += 355;
        },
        next(){
            if(this.translateNum==this.carData.length*(-355)+1065) return false;
            this.translateNum -= 355;
        },
    }
}
</script>

<style scoped>
.btn-prev{
    left: 0;
}
.btn-next{
    right: 0;
}
.btn-prev.disabled,.btn-prev.disabled:hover,.btn-next.disabled,.btn-next.disabled:hover{
    color: #ccc;
}
.btn-prev:hover,.btn-next:hover{
    color: #1c91ff;
    background: #e3effa;
}
.btn-prev,.btn-next{
    width: 40px;
    height: 100px;
    line-height: 100px;
    border-radius: 2px;
    text-align: center;
    font-size: 40px;
    color: #0169ca;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #f1f5f9;
    cursor: pointer;
}
.carousel-item-info .price{
    color:#f9773b;
}
.carousel-item-info p:first-child{
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    font-weight: bold;
}
.carousel-item-info p:last-child{
    line-height: 30px;
    font-size: 14px;
}
.carousel-item-info span{
    color: #666;
    margin-right: 20px;
}
.carousel-inner{
    width: 1800px;
    transition: transform 1.5s;
}
.carousel-item-info{
    height: 90px;
    padding: 12px 20px;
    background: #f1f5f9;
    transition: all 0.3s;
}
.carousel-item-pic{
    width: 340px;
    height: 300px;
}
.carousel-item{
    width: 340px;
    height: 390px;
    margin-right: 15px;
    /* background: #eee; */
    /* padding: 30px 5px; */
    box-sizing: border-box;
}
.carousel-item:hover{
    box-shadow: 0 5px 8px #889;
}
.carousel-item:hover .carousel-item-info{
    background: #367bff;
    color: #fff;
}
.carousel-item:hover .carousel-item-info span{
    color: #fff;
}
.carousel-box{
    height: 390px;
    width: 1050px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.feature-carousel{
    height: 390px;
    width: 100%;
    position: relative;
    margin-top: 60px ;
}
.t-top{
    display: flex;
    justify-content: space-between;
    height: 400px;
    overflow: hidden;
}
.t-left,.t-right{
    width: 320px;
    height: 100%;
}
.t-right{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.t-right>div{
    height: calc( 50% - 8px);
}
.center{
    width: calc(100% - 670px);
}
.center >>> .el-carousel__button{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.imgbox{
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
}
.imgbox .title{
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to top,rgba(0, 0, 0,.9) 0%,rgba(0, 0, 0,.7) 40%, transparent);
    width: 100%;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
}
</style>